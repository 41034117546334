import { render, staticRenderFns } from "./danMu.vue?vue&type=template&id=981ab00a&scoped=true"
import script from "./danMu.vue?vue&type=script&lang=js"
export * from "./danMu.vue?vue&type=script&lang=js"
import style0 from "./danMu.vue?vue&type=style&index=0&id=981ab00a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "981ab00a",
  null
  
)

export default component.exports