import { render, staticRenderFns } from "./MessageView.vue?vue&type=template&id=7f16efcc&scoped=true"
import script from "./MessageView.vue?vue&type=script&lang=js"
export * from "./MessageView.vue?vue&type=script&lang=js"
import style0 from "./MessageView.vue?vue&type=style&index=0&id=7f16efcc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f16efcc",
  null
  
)

export default component.exports